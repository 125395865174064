/* eslint-disable react-hooks/exhaustive-deps */

import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactForm from "components/ContactForm"
import Popup from "components/Popup"

import { Apartments, Baner, News } from "page_components/investment"

import {
  Hero,
  Nav,
  Gallery,
  About,
  Location,
  Convenients,
} from "page_components/investment/sky-trust"

const Investment = ({ data, location }) => {
  const title = "Sky Trust"
  const allApartments = data.allWpApartmentSkyTrust.nodes

  return (
    <Layout
      location={location}
      seo={{
        title: "Sky Trust - Sięgnij wyżej",
        description:
          "Spektakularne apartamenty i prestiżowe powierzchnie biurowe w topowej lokalizacji. Kielce przyszłości zaczynają się w Sky Trust.",
      }}
      rmGlobalPopup
    >
      <Breadcrumbs
        title={title}
        parent={{ label: "Kielce", url: "/mieszkania/kielce" }}
        subParent={{ label: "Mieszkania", url: "/mieszkania" }}
      />
      <Hero title={title} apartmentList={allApartments} />
      <Nav title={title} investmentRaw="sky_trust" />

      <Apartments
        apartmentList={allApartments}
        investment="Sky Trust"
        investmentRaw="sky_trust"
      />
      <Baner />
      <News investment="sky_trust" city="Kielce" />
      <Gallery />
      <About title={title} />
      <Location />
      <Convenients />
      <ContactForm
        formType="sale"
        investment="Sky Trust"
        investmentRaw="sky_trust"
      />
      <Popup type="kielce" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpApartmentSkyTrust(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        slug
        acfApartment {
          investment
          type
          apartmentNumber
          buildingNumber
          area
          floor
          location
          price
          priceForSquareMeter
          roomsnumber
          status
          visualization2d {
            sourceUrl
          }
          placesList {
            area
            name
          }
        }
      }
    }
  }
`

export default Investment
